import { useState, useEffect } from "react";

function Navbar() {
  const [navActive, setNavActive] = useState(false);

  const toggleNav = () => {
    setNavActive(!navActive);
  };

  const closeMenu = () => {
    setNavActive(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        closeMenu();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 1200) {
      closeMenu();
    }
  }, []);

  return (
    <nav className={`navbar ${navActive ? "active" : ""}`}>
      <a
        className={`nav__hamburger ${navActive ? "active" : ""}`}
        onClick={toggleNav}
      >
        <span className="nav__hamburger__line"></span>
        <span className="nav__hamburger__line"></span>
        <span className="nav__hamburger__line"></span>
      </a>
      <div className={`navbar--items ${navActive ? "active" : ""}`}>
        <ul>
          <li>
            <a
              href="#heroSection"
              onClick={closeMenu}
              className="navbar--content"
            >
              Home
            </a>
          </li>
          <li>
            <a
              href="#MyPortfolio"
              onClick={closeMenu}
              className="navbar--content"
            >
              Portfolio
            </a>
          </li>
          <li>
            <a
              href="#AboutMe"
              onClick={closeMenu}
              className="navbar--content"
            >
              About Me
            </a>
          </li>
        </ul>
      </div>
      <a
        href="mailto:sn.riivera@gmail.com"
        onClick={closeMenu}
        className="btn btn-outline-primary"
      >
        Contact Me
      </a>
    </nav>
  );
}

export default Navbar;
