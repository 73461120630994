import React from "react";

function Footer() {
  return (
    <footer className="footer--container">
      <hr className="divider" />
      <div className="footer--content--container">
        <p className="footer--content">Made with 💖 by Nicolo using React JS, HTML, CSS, Bing Image Creator powered by DALL-E3 and Netlify</p>
      </div>
    </footer>
  );
}

export default Footer;
