export default function AboutMe() {
  return (
    <section id="AboutMe" className="about--section">
      <div className="about--section--img">
        <img src="./img/about-me.png" alt="About Me" />
      </div>
      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">
          <p className="section--title">About Me</p>
          <h1 className="skills-section--heading">Outside Of Work</h1>
          <p className="hero--section-description">
          You might catch me trying my hand at badminton or volleyball, though I have to admit I'm often the team's designated benchwarmer. Travel is my ultimate love, and if you get me started on it, be prepared for a never-ending stream of stories. When it comes to food, well, let's just say I'm a die-hard foodie. Care to bet $5 that I can handle spicier dishes than you? Challenge accepted! 
          </p>
        </div>
      </div>
    </section>
  );
}
