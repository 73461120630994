export default function HeroSection() {
  const email = "sn.riivera@gmail.com"; // Replace with your email address

  return (
    <section id="heroSection" className="hero--section">
      <div className="hero--section--content--box">
        <div className="hero--section--content">
          <p className="section--title">Hey, I'm Nicolo!</p>
          <h1 className="hero--section--title">
            <span className="hero--section-title--color">Engineer Turned</span>{" "}
            <br />
            Product Person
          </h1>
          <p className="hero--section-description">
            Recent engineering graduate pursuing product-related
            <br /> new-grad opportunities for 2023!
          </p>
          <div className="button-container">
            <a
              href="https://www.linkedin.com/in/snrivera/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn btn-primary">LinkedIn</button>
            </a>
            <a href={`mailto:${email}?subject=Resume%20Request`} style={{ marginLeft: '10px' }}>
              <button className="btn btn-primary">Request CV</button>
            </a>
          </div>
        </div>
      </div>
      <div className="hero--section--img">
        <img src="./img/hero_img.png" alt="Hero Section" />
      </div>
    </section>
  );
}
